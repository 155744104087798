import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import API from "./api/api";
import NoData from "./component/NoData";
import { useMutation, useQuery } from "@tanstack/react-query";
import MoonLoader from "react-spinners/MoonLoader";

const QrPage = () => {
  const { qrAccessToken } = useParams();

  //   GET QR
  const getQR = async () => {
    const { data } = await API("GET", `/qr/device/${qrAccessToken}`);
    return data;
  };
  const { data: qrData, isLoading } = useQuery({
    queryKey: ["qrData"],
    queryFn: getQR,
  });

  //   Manual input
  const [inputAmount, setInputAmount] = useState(null);

  const handleBlur = (val) => {
    let value = parseFloat(val).toFixed(2);
    setInputAmount(value);
  };

  //   Pay
  const handlePay = async (amount) => {
    const url = "https://api.pay.nebula.payrallel.io/transaction/payment/url";
    const data = {
      amountInCents: amount * 100,
      t05payaccesstoken: qrData.data.t05PayAccessToken,
      currency: qrData.Currency,
    };
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accesstoken: qrData.data.t05PayAccessToken,
        },
        body: JSON.stringify(data),
      });

      const result = await response.text();
      if (result) {
        window.location.href = result; // Redirect to the external domain
      } else {
        alert("No redirect URL provided in the response.");
      }
    } catch (error) {
      console.error("Error:", error);
      // alert("Failed to post data.");
    }
  };

  return (
    <div className="text-center bg-light" style={{ height: "100vh" }}>
      {!qrData ? (
        // <NoData text="Loading..." />
        <div
          style={{ height: "100vh" }}
          className="w-100 d-flex justify-content-center align-items-center"
        >
          <MoonLoader
            color={"black"}
            loading={!qrData}
            size={50}
            aria-label="Loading..."
          />
        </div>
      ) : (
        <div className="bg-light">
          <div className="container-fluid text-center">
            <p className="lead text-center pt-5">Welcome to</p>
            <h2 className="text-center mb-4 text-uppercase">
              {qrData?.data?.name ?? `Device Name`}
            </h2>

            <div>
              {!qrData?.acceptedPrices && !qrData?.tokenPackage && (
                <div className="mx-2">
                  <p className="lead text-center" id="ap-currency">
                    Insert amount: ({qrData?.Currency})
                  </p>

                  <input
                    className="form-control"
                    type="number"
                    step="0.01"
                    value={inputAmount}
                    required
                    onChange={(e) => setInputAmount(e.target.value)}
                    onBlur={(e) => handleBlur(e.target.value)}
                    placeholder="0.00"
                  />
                  <button
                    onClick={() => handlePay(inputAmount)}
                    disabled={!inputAmount}
                    className="btn w-100 mt-3"
                    type="button"
                    style={{
                      backgroundColor: "#00284C",
                      color: "#FFFFFF",
                    }}
                  >
                    Pay
                  </button>
                </div>
              )}

              {qrData?.acceptedPrices && (
                <div className="mx-2">
                  <p className="lead text-center" id="ap-currency">
                    Select amount: ({qrData?.Currency})
                  </p>
                  <div className="d-flex justify-content-between flex-wrap w-100">
                    {qrData?.data?.availableAcceptedPrice?.map((price, i) => (
                      <button
                        key={i}
                        className="btn btn-lg my-1 py-4"
                        type="button"
                        style={{
                          width: "48%",
                          backgroundColor: "#00284C",
                          color: "#FFFFFF",
                        }}
                        onClick={() => {
                          handlePay(price);
                        }}
                      >
                        <span className="fw-bold"> {price.toFixed(2)}</span>
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {qrData?.tokenPackage && (
                <>
                  <p className="lead text-center mt-5 fw-bold">OR</p>

                  <div className="mx-2">
                    <p className="lead text-center">Select package:</p>
                    <div className="d-flex justify-content-between flex-wrap w-100">
                      {qrData?.data?.tokenPackage?.map((pkg, i) => (
                        <button
                          key={i}
                          className="btn btn-lg my-1 py-3"
                          type="button"
                          style={{
                            width: "100%",
                            backgroundColor: "#00284C",
                            color: "#FFFFFF",
                          }}
                          onClick={() => {
                            handlePay(pkg.amount);
                          }}
                        >
                          <span>{pkg?.name}</span> <br />{" "}
                          <span className="fw-bold">
                            {qrData?.Currency} {pkg.amount.toFixed(2)}
                          </span>
                        </button>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QrPage;
