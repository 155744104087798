import React from "react";
import QrPage from "./QR";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Status from "./Status";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/qr/:qrAccessToken" element={<QrPage />} />
          <Route path="/status" element={<Status />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
