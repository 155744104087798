import axios from "axios";
// eslint-disable-next-line no-unused-vars

export const endpoint = "https://api.pay.nebula.payrallel.io";

const instance = axios.create({ baseURL: endpoint });

const API = (method, url, data, options) => {
  return instance({ method, url, data, ...options });
};

export default API;
