import moment from "moment";
import React from "react";
import { useSearchParams } from "react-router-dom";
import cc from "currency-codes";

const Status = () => {
  const [params] = useSearchParams();

  //   Timestamp
  function formatTimestamp(encodedTimestamp) {
    // Decode the URL-encoded string
    const decodedTimestamp = decodeURIComponent(encodedTimestamp);

    // Use moment.js to parse, convert to local time, and format the date
    const formattedDate = moment(decodedTimestamp)
      .local()
      .format("DD MMMM YYYY, HH:mm");

    return formattedDate;
  }

  return (
    <div className="container">
      <div className="mt-5 w-100 d-flex align-items-center justify-content-center">
        <img
          className="w-75"
          src={
            params.get("status") === "success"
              ? require("./assets/img/success.jpg")
              : require("./assets/img/fail.jpg")
          }
        />
      </div>
      <h1
        className="text-center my-2 text-uppercase fw-bold"
        style={{ color: "#00284C" }}
      >
        {params.get("status") === "success"
          ? "Payment Success!"
          : "Payment Failed!" ?? "Payment Status"}
      </h1>

      <p className="text-center mt-2 text-secondary text-sm">
        created at {formatTimestamp(params.get("createdAt"))}
      </p>

      {/* Transaction ID */}
      <p className="text-center my-4 " style={{ color: "#00284C" }}>
        <span>Transaction ID:</span> {params.get("transactionId")}
      </p>
      {/* <div className="mx-3 mt-4 d-flex justify-content-between">
        <div style={{ maxWidth: "49%" }}>
          <p className="text-secondary my-2 fw-bold">Transaction ID</p>
        </div>
        <div style={{ maxWidth: "49%" }}>
          <p
            className="text-secondary my-2 text-end"
            style={{ wordBreak: "break-word" }}
          >
            {params.get("transactionId")}
          </p>
        </div>
      </div> */}

      {/* Amount */}
      <div className="mx-3 d-flex justify-content-between">
        <div style={{ maxWidth: "49%" }}>
          <p className="text-secondary my-2 fw-semibold">Amount</p>
        </div>
        <div style={{ maxWidth: "49%" }}>
          <p className="text-secondary text-end my-2  fw-semibold">
            {cc.number(params.get("currency")).code ?? ""}{" "}
            {params.get("amountInCents") / 100}
          </p>
        </div>
      </div>

      {/* Payment Type */}
      <div className="mx-3 d-flex justify-content-between">
        <div style={{ maxWidth: "49%" }}>
          <p className="text-secondary my-2 fw-semibold">Payment Type</p>
        </div>
        <div style={{ maxWidth: "49%" }}>
          <p className="text-secondary text-end my-2 text-uppercase  fw-semibold">
            {params.get("paymentType")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Status;
